import type { ReactElement } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Box,
  Container,
} from '@material-ui/core';
import clsx from 'classnames';

import useStyles from './ModalDialog.style';

import { closeDialog, openDialog } from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';

/**
 * A component that displays an overlay that a user should connect their wallet
 *
 * @returns The `ConnectOverlay` component
 */
export function ModalDialog({ ...t }): ReactElement {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();

  /** If the screen size is small or below */
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { dialogOpen: isOpen } = useAppSelector((state) => state.app);

  /** A function that handles the opening of the modal */
  const handleOpen = () => dispatch(openDialog());

  /** A function that handles the closing of the modal */
  const handleClose = () => dispatch(closeDialog());

  return (
    <div {...t}>
      <Container>
        <Dialog
          fullWidth
          maxWidth={isSmall ? 'sm' : 'md'}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          {/* Testing */}
          <DialogTitle>
            This is the New Web3 Internet on the Blockchain!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Paying for products in Crypto IS Different!
            </DialogContentText>
            <ol>
              <li>USE METAMASK for the best Experience.</li>
              <li>
                Send ETHEREUM from your CoinBase Account to your MetaMask
                Wallet.
              </li>
              <li>
                USE the Browser inside METAMASK on mobile (or the chrome
                extension on Desktop)!
              </li>
              <li>
                Connect your MetaMask Wallet to this dApp using the Red button
                on the top right of the screen.
              </li>
              <li>
                Select GET YOUR TICKET and purchase the amount of NFT
                experiences that you want for this exclusive event.
              </li>
              <li>
                Check back frequently using your NFT to find out when the
                exclusive rewards and events will be announced.
              </li>
            </ol>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
