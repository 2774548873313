import type { ReactElement } from 'react';

import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Web3 from 'web3';

import {
  BasicView,
  StaticCardDisplay,
  ModalDialog,
  Description,
} from '../../components';
import { setAffiliateAddress, openDialog, closeDialog } from '../../features';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getRoute } from '../../router';

import useStyles from './Home.style';

/**
 * A view that serves as the home page
 *
 * @returns The `Home` view
 */
export function Home(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  let affiliateAddress = new URLSearchParams(history.location.search).get(
    'affiliate',
  );
  if (affiliateAddress === null) affiliateAddress = '';
  const isAddress = Web3.utils.isAddress(affiliateAddress);
  if (affiliateAddress !== '' && !isAddress) {
    alert('Affiliate Address incorrect');
    affiliateAddress = '';
  }

  dispatch(setAffiliateAddress(affiliateAddress));
  /**
   * A function that redirects the user to the purchase screen
   */
  const redirectToPurchase = () => history.push(getRoute('purchase').path);

  const { dialogOpen: isOpen } = useAppSelector((state) => state.app);

  const handleDialogOpen = () => {
    dispatch(openDialog());
  };
  const handleDialogClose = () => {
    dispatch(closeDialog());
  };

  return (
    <BasicView
      title="ART BASEL EVENT COLLECTION"
      subtitle=""
      maxWidth="xl"
      otherFont
      mobileMultiLine={['ART BASEL', 'EVENT', 'COLLECTION']}
    >
      <StaticCardDisplay
        items={[
          {
            path: '/assets/cards/digital-human.jpg',
            width: '32vw',
          },
          {
            path: '/assets/cards/sun-goddess.jpg',
            width: '32vw',
          },
          {
            path: '/assets/cards/at-times-end.jpg',
            width: '32vw',
          },
        ]}
      />

      <div className={classes.wrapper}>
        <Box my={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={redirectToPurchase}
          >
            BUY your NFT
          </Button>
        </Box>
        <Description>
          <strong>
            Exclusive Launch of the first NFT Single for the “Queen of the
            South”, Jasmin Cadavid
          </strong>
          .<br />
          <br />
          Holders of the ArtBasel.Events NFT are given a pre release preview of
          the NFT’s for sale at ArtBasel.Gallery on display live at{' '}
          <strong>
            Racket Nightclub and Gallery in Wynwood, Florida the Miami Arts
            District.
          </strong>
          .<br />
          <br />
          All holders can connect their NFT to the site to get exclusive content
          and information about the start of the auction, and the rewards that
          come with your NFT. Earn random boosts that are embedded in the NFT
          metadata that entitle you to a{' '}
          <strong>larger percentage of the profit sharing</strong>
          for our native tokens.
          <br />
          <br />
          All holders are granted{' '}
          <strong>
            invites to exclusive events that only NFT holders are allowed to
            attend in the future.
          </strong>
          .
          <br />
          <br />
          All holders are entitled to early buy-in at the event for the first
          time release of our exclusive line of{' '}
          <strong>augmented reality gaming assets</strong>. Become a part of the
          experience. Own your reality.
        </Description>
      </div>
      <ModalDialog isOpen={isOpen} handleClose={handleDialogClose} />
    </BasicView>
  );
}
