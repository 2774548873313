/* eslint no-underscore-dangle: "warn" */

import { ReactElement, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  FormControl,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'classnames';
import { ethers } from 'ethers';

import { BasicView, Description } from '../../components';
import { useContracts, useAppSelector } from '../../hooks';

import useStyles from './Purchase.style';

// import { useAppSelector } from 'hooks';

// import {
//   CardPurchase,
//   getPurchaseOption,
//   getPurchaseOptions,
// } from 'constants/cards';
// import { MOST_EXPENSIVE_PACK } from 'hooks/useContracts';
// import { formatAbbreviated } from 'utils/formatting';

/** The id of the default pack selection */
// const DEFAULT_SELECTION = 99;

/**
 * A view that presents the user with a purchase screen
 *
 * @returns The `Purchase` view
 */
export function Purchase(): ReactElement {
  const classes = useStyles();
  const { connected, web3Provider, samurai, purchasing } = useContracts();

  const purchaseUpLimit = 1000;

  const purchaseDownLimit = 1;
  /** A state that stores which pack the user has selected */
  const [selectedAmount, setSelectedAmount] = useState(purchaseDownLimit);

  /** If the user has selected a pack */
  const hasSelected = selectedAmount !== 0;

  /**
   * A function that handles the user change of the pack
   *
   * @param event The event to use for the changing
   */
  // const handlePackChange = (
  //   event: ChangeEvent<{
  //     name?: string | undefined;
  //     value: unknown;
  //   }>,
  // ) => {
  //   let val;
  //   if ((event.target.value as number) < 0) val = 0;
  //   else if ((event.target.value as number) > 30) val = 30;
  //   else val = event.target.value as number;
  //   setSelectedAmount(val);
  // };
  const evaluate = (value: number) => {
    let val;
    if (value < purchaseDownLimit) val = purchaseDownLimit;
    else if (value > purchaseUpLimit) val = purchaseUpLimit;
    else val = value;
    setSelectedAmount(val);
  };

  const decrease = () => {
    const val = selectedAmount - 1;
    evaluate(val);
  };

  const increase = () => {
    const val = selectedAmount + 1;
    evaluate(val);
  };

  const getUserAddress = async () => web3Provider.provider.selectedAddress;

  const getUserBalance = async (address: string) =>
    web3Provider.getBalance(address);

  // get the current buy price before each mint as there is a price curve in the contract
  const getBuyPrice = async () => {
    let buyPrice = await samurai.getNFTPrice(selectedAmount);
    buyPrice = Number(
      ethers.utils.formatEther(
        ethers.BigNumber.from(parseInt(buyPrice._hex, 16).toString()),
      ),
    );
    // check if user has enough ether to buy the selected amount
    const address = await getUserAddress();
    let userBalance = await getUserBalance(address);
    userBalance = Number(
      ethers.utils.formatEther(
        ethers.BigNumber.from(parseInt(userBalance._hex, 16).toString()),
      ),
    );
    if (userBalance < buyPrice) return false; // need a toast here -- not enough eth to buy tickets
    return buyPrice;
  };

  // const getOwner = async () => {
  //   const ret = await samurai.owner()
  // };

  const { affiliateAddress } = useAppSelector((state) => state.app);

  const buy = async () => {
    const price = await getBuyPrice();
    // if there is no Affiliate URL Query param, use this return value as the second parameter in the mintTicket function
    const ownerAddress = await samurai.owner();
    const mintAddress =
      affiliateAddress === '' ? ownerAddress : affiliateAddress;
    console.log('mintAddress = ', mintAddress);
    if (!price) return;

    const signer = samurai.connect(web3Provider.getSigner());
    // TODO: Affiliate link as second parameter, if none is set then use getOwner's result above
    console.log(price);
    await signer
      .mintTicket(selectedAmount, mintAddress, {
        value: ethers.utils.parseEther(String(price)),
      })
      .then((res: any) => console.log(res));
  };

  const getBuyButtonText = () => {
    if (!connected) {
      return `NOT CONNECTED`;
    }

    if (!hasSelected) {
      return `PLEASE SELECT AN OPTION`;
    }

    if (purchasing) {
      return `PURCHASING...`;
    }

    return `Get My Ticket`;
  };

  return (
    <>
      {/* {successfullyPurchased && <UnlockedOverlay />} */}
      <BasicView
        title="VIP Tickets"
        subtitle="Purchase a ticket to attend the party"
        maxWidth="md"
        otherFont
      >
        <Paper className={classes.card}>
          <Box p="1.5rem" textAlign="center">
            <Typography
              className={clsx(classes.baseText, classes.letterSpacing)}
              variant="h4"
            >
              How does it work?
            </Typography>
            <Typography
              variant="h6"
              className={clsx(classes.baseTextBlack, classes.letterSpacing)}
            >
              The ticket price follows a demand curve that will start the price
              at 0.25 ETH and will increase as demand and sales for the ticket
              increase. The first buyers in can resell their tickets for a
              profit
            </Typography>
            <Box my={1} />
            {/* <Typography variant="h5">Available: {USER_BALANCE} BNB</Typography> */}
          </Box>
          <Divider className={classes.divider} />
          <Box
            p="1.5rem"
            className={clsx(classes.alignCenter, classes.letterSpacing)}
          >
            <Typography variant="h4" className={classes.baseText}>
              Number of Tickets to purchase
            </Typography>
            <Box mt={2} mb={4}>
              <FormControl variant="outlined" className={classes.selector}>
                {/* <TextField
                  id="standard-number"
                  // label="Number"
                  type="number"
                  value={selectedAmount}
                  onChange={handlePackChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <Grid container spacing={0}>
                  <Grid item md={4} xs={12} />
                  <Grid item md={4} xs={12}>
                    <div className={classes.amount_wrapper}>
                      <Button
                        className={classes.down_count}
                        title="Down"
                        onClick={() => decrease()}
                      >
                        <RemoveIcon />
                      </Button>
                      <div className={classes.amount}>{selectedAmount}</div>
                      <Button
                        className={classes.up_count}
                        title="Up"
                        onClick={() => increase()}
                        disabled={!connected}
                      >
                        <AddIcon />
                      </Button>
                    </div>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.buyButton}
                      disabled={!connected}
                      onClick={() => buy()}
                    >
                      BUY
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12} />
                </Grid>
              </FormControl>
            </Box>
          </Box>
        </Paper>
        <Box mt={8} />
        <Description title="How does it work?">
          ART | BASEL Events: This is a collection of NFT admission passes to
          the Winter 2021 EtherStream growth hacker launch party in WynWood
          Miami at Racket Night Club happening December 3, 2021 at 9:00 PM EST.
          <br />
          <br />
          Only 1,000 NFT Admissions passes can ever be minted for this
          collection that will always link to a video that saves the experience
          on the blockchain so the owner can always log in to view the party
          in-dapp forever.
          <br />
          <br />
          Check in frequently with your NFT events pass to find out when your
          rewards and exclusive events will be announced.
          <br />
          <br />
        </Description>
        <Box mt={5} />
        <Description title="What do I get with an Art Basel admission?">
          The tickets have secret metadata that lets you see the video of the
          events in-app and they are also loaded with metadata to include boosts
          you can use for a few things on platform like:
          <br />
          <br />
          <ul>
            <li>Boost your Dividend Payout</li>
            <li>Boost your Power to Govern</li>
            <li>Boost your VICEx Lifestyle Holder Status</li>
          </ul>
          <br />
          <br />
          This is also a NFT Trading Post and you will be able to resell and
          trade your Art Basel Admissions for other boosts after the main event.
          List your tickets and buy more tickets to obtain more platform boosts
          to supercharge your rewards.
        </Description>
      </BasicView>
    </>
  );
}
