import { ReactElement, useState } from 'react';

import {
  Box,
  Divider,
  Paper,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
import clsx from 'classnames';

import { BasicView } from '../../components';
import { useContracts } from '../../hooks';

import useStyles from './Catalog.style';

// import { useApi, useContracts } from 'hooks';
// import { SamuraiDetails } from 'interfaces';

// const AMOUNT_PER_PAGE = 12;

export function Catalog(): ReactElement {
  const classes = useStyles();
  const { connected, user, affiliateBalance, samurai, signer } = useContracts();
  const claim = async () => {
    const samuraiSigner = samurai.connect(signer);
    samuraiSigner
      .claim()
      .then((res: any) => console.log(res))
      .catch((e: any) => console.error(e));
  };
  return (
    <>
      {/* {successfullyPurchased && <UnlockedOverlay />} */}
      <BasicView
        title="Affiliates"
        subtitle="Become an Affiliate and Earn ETH"
        maxWidth="md"
        otherFont
      >
        <Grid container spacing={1} className={classes.marginBottom2}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.card}>
              <Box p="1.5rem" textAlign="center">
                <Typography
                  className={clsx(classes.baseText, classes.letterSpacing)}
                  variant="h6"
                >
                  Affiliate balance
                </Typography>
                <Typography
                  className={clsx(classes.baseText, classes.letterSpacing)}
                  variant="h6"
                >
                  {affiliateBalance}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className={classes.marginAuto}>
            <Box textAlign="center" p="0.5rem">
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!connected || affiliateBalance === 0}
                className={classes.button}
                // className={'rsun-tracker-button claim-button button-top'}
                onClick={claim}
              >
                Claim Yours
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Paper className={classes.card}>
          <Box p="1.5rem" textAlign="center">
            <Typography
              className={clsx(classes.baseText, classes.letterSpacing)}
              variant="h4"
            >
              Earn 10% of the ticket sales when someone purchases an ArtBasel
              NFT using your affiliate link.
            </Typography>
            <Box my={1} />
            {/* <Typography variant="h5">Available: {USER_BALANCE} BNB</Typography> */}
          </Box>
          <Divider className={classes.divider} />
          <Box p="1.5rem" className={classes.alignCenter}>
            <Typography
              variant="h6"
              className={clsx(classes.affiliateLink, classes.letterSpacing)}
            >
              <p>Simply add your ETH address to the url like this:</p>
              https://artbasel.events/?affiliate=
              <span className={classes.addressText}>YOUR_ETH_ADDRESS</span>
            </Typography>
          </Box>
        </Paper>
      </BasicView>
    </>
  );
}
