import { ReactElement } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import clsx from 'classnames';

import { BasicView } from '../../components';

import useStyles from './Offer.style';

export function Offer(): ReactElement {
  const classes = useStyles();

  return (
    <>
      {/* {successfullyPurchased && <UnlockedOverlay />} */}
      <BasicView title="Offer" maxWidth="md" otherFont>
        <Paper className={classes.card}>
          <Box p="1.5rem" className={classes.alignCenter}>
            <Typography
              variant="h6"
              className={clsx(classes.affiliateLink, classes.letterSpacing)}
            >
              ArtBasel.Events ticket NFTs entitle all holders to the most rare
              collection of drops from ArtBasel.Gallery studios on all future
              releases.
            </Typography>
            <br />
            <Typography
              variant="h6"
              className={clsx(classes.affiliateLink, classes.letterSpacing)}
            >
              Holders of the ArtBasel.Events NFT are given a pre release preview
              of the NFT&apos;s for sale at the coming ArtBasel.Gallery on
              display live, at Racket Nightclub and Gallery in Wynwood, Florida
              at the Miami Arts District.
            </Typography>
            <br />
            <Typography
              variant="h6"
              className={clsx(classes.affiliateLink, classes.letterSpacing)}
            >
              All holders can connect their NFT to our site to get exclusive
              content and information about the start of the auction.
            </Typography>
            <br />
            <Typography
              variant="h6"
              className={clsx(classes.affiliateLink, classes.letterSpacing)}
            >
              All holders are entitled to early buy-in at the event for the
              first time release of our exclusive line of augmented reality
              gaming assets. Become a part of the experience. Own your reality.
            </Typography>
          </Box>
        </Paper>
      </BasicView>
    </>
  );
}
