import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
// import clsx from 'classnames';
import { useParams } from 'react-router-dom';

import useStyles from './Details.style';

import { BasicView, DynamicCard, Loading } from 'components';
import { useApi } from 'hooks';
import { Attribute, SamuraiDetails } from 'interfaces';
import { formatCardNumber } from 'utils/formatting';

/**
 * The property types which are used by the `Details` view routing parameters
 */
export interface DetailsRouteProps {
  id: string;
}

export interface DetailsInfo {
  key: string;
  value: string;
}

/**
 * A view that displays details about a Samurai
 *
 * @returns The `Details` view
 */
export function Details(): ReactElement {
  const classes = useStyles();
  const api = useApi();
  const params: DetailsRouteProps = useParams();

  const [samuraiData, setSamuraiData] = useState<SamuraiDetails>();
  const [ticketInfo, setTicketInfo] = useState<DetailsInfo[]>([]);

  const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

  const timeConverter = (UnixTimestamp: string | number) => {
    let timeStamp = 0;
    if (typeof UnixTimestamp === 'string')
      timeStamp = parseInt(UnixTimestamp, 2);
    else timeStamp = UnixTimestamp;
    const a = new Date(timeStamp * 1000);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = `0${a.getMinutes()}`;
    const sec = `0${a.getSeconds()}`;
    const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
    return time;
  };

  const fetchData = async () => {
    try {
      const { data } = await api.get(`/${params.id}`);
      console.log(data);
      setSamuraiData(data);
      const arr = [];
      arr.push({ key: 'Name', value: data.name });
      arr.push({ key: 'EXT Url', value: data.external_url });
      arr.push({ key: 'Video', value: data.video });
      arr.push({ key: 'BG Color', value: data.background_color });
      data.attributes.map((attr: Attribute) => {
        let val = attr.value;
        if (attr.display_type !== undefined) {
          switch (attr.display_type) {
            case 'date':
              val = timeConverter(val);
              break;
            case 'boost_percentage':
              val += '%';
              break;
            default:
              break;
          }
        }
        arr.push({ key: capitalize(attr.trait_type), value: val });
        return true;
      });
      setTicketInfo(arr);
    } catch {
      console.error('No data found!');
    }
  };

  // fetchData();
  useEffect(() => {
    fetchData();
  }, []);

  const getCardAttributes = () => {
    if (samuraiData) {
      // let str = samuraiData.Entitlements;
      // str = str.replace('[', '').replace(']', '');
      // const arr = str.split(', ');
      // return arr;
      const arr = samuraiData.attributes.filter(
        (attr) => attr.trait_type === undefined,
      );
      return arr;
    }
    return [];
  };

  if (!samuraiData) {
    return <Loading />;
  }

  return (
    <BasicView title="NFT Details" subtitle={samuraiData.description}>
      <Paper>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <DynamicCard
                id={params.id}
                width="100%"
                // characterPath={samuraiData.image}
                backgroundPath={samuraiData.image}
                cardOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                mb={5}
                // display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  {ticketInfo.map((info, i) => (
                    <Box py={2} key={info.key}>
                      <Grid
                        container
                        // justifyContent="space-around"
                        alignItems="center"
                        className={classes.breakWord}
                      >
                        <Grid item xs={6}>
                          <Typography variant="h6">{info.key}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6">{info.value}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}

                  {getCardAttributes().length > 0 ? (
                    getCardAttributes().map((cardAttribute, i) => (
                      <Box py={2} key={cardAttribute.value}>
                        <Grid
                          container
                          // justifyContent="space-around"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Typography variant="h6">
                              Attribute {i + 1}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6">
                              {cardAttribute.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <span className={classes.number}>
                  {formatCardNumber(params.id)}
                </span>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </BasicView>
  );
}
