import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `Details` view
 */
export default makeStyles(() => ({
  content: {
    textAlign: 'center',
    color: '#000',
    width: '100%',
    fontFamily: 'SuperStar',
    fontSize: 40,
  },
  card: {
    backdropFilter: 'blur(12px) saturate(200%)',
    backgroundColor: 'rgba(17, 25, 40, 0.5)',
    borderRadius: 12,
    border: '1px solid rgba(255, 255, 255, 0.125)',
  },
  baseText: {
    color: 'white',
  },
  marginBottom2: {
    marginBottom: '2rem',
  },
  addressText: {
    color: 'black',
    wordBreak: 'break-word',
  },
  divider: {
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  alignCenter: {
    textAlign: 'center',
  },
  affiliateLink: {
    color: 'white',
    display: 'inline',
  },
  letterSpacing: {
    letterSpacing: '2px',
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(17, 25, 40, 0.4)',
    },
    fontSize: '1.5em',
    backdropFilter: 'blur(12px) saturate(200%)',
    backgroundColor: 'rgba(253, 14, 53, 0.72)',
    borderRadius: 12,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  marginAuto: {
    margin: 'auto 0',
  },
}));
